body,
html,
:root > div {
  width: auto;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Roboto Mono", monospace;
  text-align: center;
  overflow-x: hidden;
  /* overflow: hidden; */
}

h1 {
  font-family: "Kufam", cursive !important;
  line-height: 1.5;
  font-size: 7vh;
}

h2 {
  font-family: "Gayathri", sans-serif !important;
}

h3 {
  font-size: 3vh;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  margin: 1.5%;
  color: #ffffff;
  overflow: hidden;
}

.mode {
  /* height: 100vh; */
  overflow: hidden;
}

.dark-mode {
  background-color: #2a2c31;
  color: #fffff7;
  font-weight: bold;
  /* height: auto; */
}

.light-mode {
  background-color: #fffff7;
  color: #20295a;
  font-weight: bold;
  /* height: auto; */
}

svg {
  margin-top: 8%;
  margin-bottom: 2%;
  justify-content: center;
  width: 80vh;
}

input {
  height: 6vh;
  text-align: left;
  padding-left: 2%;
  font-size: 2vh;
  font-weight: bold;
}

textarea {
  text-align: left;
  padding-left: 2%;
  font-size: 2vh;
  font-weight: bold;
}

.icon-toggle {
  position: fixed;
  cursor: pointer;
  top: 3vh;
  right: 5vh;
  z-index: 3;
}

/*  ================== NAVBAR ====================== */

nav.responsive-nav {
  position: fixed;
  width: 100%;
  min-height: 12vh;
}

nav.responsive-nav > ul {
  display: flex;
}

nav > ul > li {
  list-style-type: none;
  padding: 20px 5px;
  float: right;
}

nav > ul > li > a {
  cursor: pointer;
  padding: 15px 25px;
}

a:link {
  text-decoration: none !important;
}

.logo-nav {
  cursor: pointer;
  float: left;
  display: none !important;
}

.navbar-link {
  font-size: 2.5vh;
}
/*  ================== ABOUT ME ====================== */
.terminal {
  border: 5px solid #535353;
  height: max-content;
  background-color: black;
  margin: 2% 20%;
  color: green;
  text-align: left;
}
.head {
  justify-content: center;
  background: #535353;
  width: 100%;
  height: 8vh;
  display: flex;
}

.about-me {
  text-align: left;
  font-size: 2.7vh;
  line-height: 2;
  margin: 2% 0% 0% 2%;
}

.icon-skill {
  color: #ffffff;
  margin: 10%;
}
.title {
  font-size: 5vh;
  overflow: hidden;
}

.fas {
  align-self: center;
  margin-left: 1%;
  margin-right: 1%;
  color: #ffffff;
}

/*  ================== MY WORK ====================== */

.work-experience {
    margin-bottom: 1%;
}

.my-work-section {
  height: fit-content;
  padding: 2% 2%;
}
.card-title {
  overflow: hidden;
}
.tech_used {
  display: block;
  padding-top: 1.5%;
  color: #969696;
}
/*  ================== CONTACT ME ====================== */
.contact-me {
  padding-top: 5%;
}
.form-message {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  width: 40%;
  margin: 2% 30%;
}

.contact-me-form {
  display: flex;
  flex-direction: column;
  padding: 5%;
  align-items: flex-start;
}

.label {
  font-size: 3vh;
  justify-content: flex-start;
  margin-top: 3vh;
}

.input {
  width: 100%;
}

.btn {
  cursor: pointer;
  -webkit-border-radius: 2;
  -moz-border-radius: 2;
  border-radius: 2px;
  letter-spacing: 2px;
  color: #000000;
  /* font-size: 4vh; */
  font-weight: bold;
  background: #ffffff;
  padding: 10px 20px;
  border: solid #292929 3px;
  text-decoration: none;
}

.btn:hover {
  background: #c9c9c9;
  text-decoration: none;
}

.sm {
  cursor: pointer;
  font-size: 2vh !important;
  padding: 5px 5px !important;
  margin-left: 20vh;
  margin-top: 5vh;
}

/* =================== Media Queries =================== */

@media screen and (max-width: 768px) {
  body {
    font-size: 90%;
    overflow-x: hidden;
  }

  h3 {
    /* font-size: 2.5vh; */
    margin: 0;
  }
  svg {
    margin-top: 30%;
    margin-bottom: 10%;
    width: 90%;
  }
  /* navbar ============ */
  nav.responsive-nav {
    z-index: 2;
    height: 12vh;
  }
  nav.responsive-nav > ul {
    width: 50%;
    height: auto;
    position: fixed;
    flex-direction: column;
    transition: 3000ms ease all;
    z-index: inherit;
    top: 10vh;
  }

  .li-desactive {
    height: 100%;
    position: fixed;
    right: 0;
    flex-direction: column;
    top: 14vh;
    margin-right: 0%;
    display: none;
  }

  nav > ul > li {
    width: 100%;
    padding: 5px;
    margin-left: 10%;
    list-style-type: none;
  }

  nav.responsive-nav > ul > li > a {
    padding-right: 25%;
    cursor: pointer;
  }

  .logo-nav {
    margin-left: 5%;
    cursor: pointer;
    display: block !important;
  }

  figure {
    z-index: 3;
    cursor: pointer;
    margin-top: 5%;
    padding-left: 5%;
    width: 20%;
  }

  .navbar-link {
    padding: 10%;
  }
  /* contact me ======  */
  .title {
    overflow: hidden;
  }
  .contact-me {
    height: 100vh;
  }

  .form-message {
    width: 80%;
    margin-left: 10%;
  }

  /*  about me ===== */
  .terminal {
    margin: 25% 1%;
  }
  .head {
    height: 6vh;
  }
}
